import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Layout from "components/shared/Layout";
import Games from "components/market/Games";
import GamesRelatedContent from "components/market/GamesRelatedContent";
import CTA from "components/shared/CTA";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/game-development.svg";

export default () => (
  <>
    <Meta url="/market/games/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="Mobile Game"
      black="Index"
      text={
        <p>
          The AppInChina Mobile Game Index tracks the top 20 mobile games in
          China across all platforms (iOS and Android).
          <br />
          <b>Last Updated: Dec 2024</b>
        </p>
      }
    />
    <br />
    <br />
    <Layout>
      <GamesRelatedContent>
        <Games />
      </GamesRelatedContent>
      <br />
      <br />
    </Layout>
    <CTA titleLevel="h3" />
    <Footer titleLevel="h4" />
  </>
);
