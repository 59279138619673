import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Layout from "components/shared/Layout";
import CloudProvider from "components/market/CloudProviders";
import MarketRelatedContent from "components/market/MarketRelatedContent";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/provider.svg";
import CTA from "components/shared/CTA";
import { Link } from "gatsby";

export default () => (
  <>
    <Meta url="/market/cloud-provider/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="Cloud Provider"
      black="Index"
      text={
        <p>
          {" "}
            The AppInChina Cloud Provider Index is the market-leading index of China's major cloud providers. We update our index on a quarterly basis using the most accurate data sources available. 
            If you intend to employ a cloud provider's service for your operations in China, we're here to offer you the most up-to-date information on the most trusted local providers. For advice and insight, please <Link to="https://appinchina.co/get-started/">contact us</Link>.

          <br />
          <br />
          
          <b>Last Updated: January 2025</b>
        </p>
      }
    />
    <br />
    <br />
    <Layout>
      <MarketRelatedContent>
        <CloudProvider />
        <p>
            Source:<br></br>
            [1] Canalys, Mainland China’s cloud service grew by 11% in Q3 2024. <br></br>
            [2] Canalys, Market share analysis from Q4 2023. 
        </p>
        <p>* Market revenue was calculated from the total market spending provided by Canalys from Q3 2024 and market share values provided. The remaining percentage of market share includes Microsoft Azure China, JD Cloud, and Inspur Cloud.
        </p>
      </MarketRelatedContent>
      <br />
      <br />
    </Layout>
    <CTA titleLevel="h3" />
    <Footer titleLevel="h4" />
  </>
);
