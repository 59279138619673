import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const imgProps = {
  objectPosition: "50% 50%",
  loading: "lazy",
  layout: "fullWidth",
  placeholder: "blurred",
  alt: "App Store Icon"
};

const RAW_APP_STORES = [{'short_name': 'Huawei AppGallery',
  'image': <StaticImage src="../../images/stores/Huawei_AppGallery.svg" {...imgProps} />,
  'website': 'https://appgallery.huawei.com',
  'text': 'Huawei AppGallery',
  'mau_market': 0.248473308,
  'mau': 325072000,
  'dau': 44715100,
  'details': ['Chinese Name: 华为应用市场',
   '',
   'Huawei AppGallery is the official, pre-installed app store for all Huawei devices and Honor smartphones. Developed by Huawei, the appstore is also used in Android, HarmonyOS (Hongmeng OS), and Windows 11 operating systems. Huawei AppGallery has an in-house application rating system and a four-tier detection mechanism ensuring safe-use. It has 18 app categories and hosts content from around the world. ']},
 {'short_name': 'Tencent Appstore',
  'image': <StaticImage src="../../images/stores/tencent.png" {...imgProps} />,
  'website': 'https://sj.qq.com',
  'text': 'Tencent Appstore',
  'mau_market': 0.131454542,
  'mau': 171979000,
  'dau': 41026600,
  'details': ['Chinese Name: 应用宝',
   '',
   'Tencent Appstore, also known as "Tencent MyApp", is China\'s largest third-party Android app store. It focuses primarly on games, but also hosts entertainment and social media apps. As Tencent also owns WeChat, it has also integrated some of WeChat functions into the app store, allowing the use of WeChat Pay. ']},
 {'short_name': 'Xiaomi Market',
  'image': <StaticImage src="../../images/stores/miui.png" {...imgProps} />,
  'website': 'https://app.mi.com',
  'text': 'Xiaomi Market',
  'mau_market': 0.120234506,
  'mau': 157300080,
  'dau': 59159400,
  'details': ['Chinese Name:  小米应用商店',
   '',
   "Mi Store is Xiaomi's official app marketplace and comes pre-installed on all Xiaomi smartphones and devices. It is part of Xiaomi's own MIUI operating system."]},
 {'short_name': 'Oppo Software Store',
  'image': <StaticImage src="../../images/stores/oppo.png" {...imgProps} />,
  'website': 'https://h5.appstore.vivo.com.cn/#/',
  'text': 'Oppo Software Store',
  'mau_market': 0.110948034,
  'mau': 145150800,
  'dau': 40475800,
  'details': ['Chinese Name: OPPO软件商店',
   '',
   " OPPO Software Store is Oppo's official app store. The Oppo Software Store is popular within the younger demographic. "]},
 {'short_name': 'VIVO App Store',
  'image': <StaticImage src="../../images/stores/vivo.png" {...imgProps} />,
  'website': 'https://store.oppomobile.com',
  'text': 'VIVO App Store',
  'mau_market': 0.105352661,
  'mau': 137830500,
  'dau': 26005200,
  'details': ['Chinese Name: VIVO应用商店',
   '',
   "VIVO App Store is VIVO's official app store that is pre-installed on all VIVO smartphones. It has a large user base and robust distribution capabilities. The platform aims to create a healthy growth environment for applications and provide developers with a high-quality service platform."]},
 {'short_name': 'Honor App Market',
  'image': <StaticImage src="../../images/stores/honor_app_market.png" alt="logo" {...imgProps} />,
  'website': 'https://www.apkmirror.com/apk/honor/app-market-2/',
  'text': 'Honor App Market',
  'mau_market': 0.074278669,
  'mau': 97177100,
  'dau': 31984000,
  'details': ['Chinese Name: 荣耀应用商店',
   '',
   'Originally a brand under Huawei, Honor was established in 2013 and has quickly become a leader in the industry. The Honor App Store is the official app store for Honor smartphones and devices. ']},
 {'short_name': '360 Mobile Assistant',
  'image': <StaticImage src="../../images/stores/360.png" {...imgProps} />,
  'website': 'http://zhushou.360.cn/',
  'text': '360 Mobile Assistant',
  'mau_market': 0.038378177,
  'mau': 50209300,
  'dau': 9738300,
  'details': ['Chinese Name: 360手机助手',
   '',
   '360 Mobile Assistant is a Chinese app store from Qihoo 360, a Chinese internet security company with products including antivirus software and a popular web browser used by millions across China. ']},
 {'short_name': 'Samsung App Store',
  'image': <StaticImage src="../../images/stores/samsung_app_store.png" {...imgProps} />,
  'website': 'https://galaxystore.samsung.com',
  'text': 'Samsung App Store',
  'mau_market': 0.032392367,
  'mau': 42378200,
  'dau': 3891600,
  'details': ['Chinese Name: 三星应用商店',
   '',
   'The Samsung App Store comes preinstalled on all Samsung smartphones. In 2019, Samsung announced it would stop manufacturing mobile phones in China due to lack of demand causing the number of Samsung App Store users to drop rapidly, a trend that is likely to continue. ']},
 {'short_name': 'Baidu Mobile Assistant',
  'image': <StaticImage src="../../images/stores/baidu.png" {...imgProps} />,
  'website': 'https://mobile.baidu.com/',
  'text': 'Baidu Mobile Assistant',
  'mau_market': 0.031660183,
  'mau': 41420300,
  'dau': 3588300,
  'details': ['Chinese Name: 百度手机助手',
   "Baidu Mobile Assistant is Baidu's official app marketplace and is the default option users are met with when seaching for apps on Baidu.com. Baidu has always been China's largest search engine, even before Google left the market in 2010. It remains the most popular search engine, and is often referred to as “The Google of China”. "]},
 {'short_name': 'Wandoujia',
  'image': <StaticImage src="../../images/stores/wandoujia.png" {...imgProps} />,
  'website': 'https://www.wandoujia.com',
  'text': 'Wandoujia',
  'mau_market': 0.009422314,
  'mau': 12327000,
  'dau': 1333900,
  'details': ['Chinese Name: 豌豆荚',
   'Wandoujia was founded in 2009 by a former employee of Google China and a telecoms engineer. It was China’s 5th largest Android app store when it was purchased by Alibaba in 2016. The company developed a multimedia marketplace as an alternative to streaming large amounts of data, allowing users to search for videos across multiple platforms, download them locally, and compress them.']},
 {'short_name': 'Sogou Mobile Assistant',
  'image': <StaticImage src="../../images/stores/sogou.png" {...imgProps} />,
  'website': 'https://as.sogou.com/pc/index?searchTab=android&p=40051205',
  'text': 'Sogou Mobile Assistant',
  'mau_market': 0.00716744,
  'mau': 9377000,
  'dau': 98000,
  'details': ['Chinese Name: 搜狗手机助手',
   '',
   "Sogou's Mobile Assistant is the official app marketplace of Sogou. Sogou is originally a search engine and smartwatch company, launched in 2004. In 2006, the company invented Sogou Pinyin, which is now the dominant input platform in China for both desktop and mobile systems. They are currently majority-owned by Tencent, and it is the only search engine able to search WeChat public accounts."]},
 {'short_name': 'Zhuoyi',
  'image': <StaticImage src="../../images/stores/zhuoyi.png" {...imgProps} />,
  'website': 'https://market.droi.com',
  'text': 'Zhuoyi',
  'mau_market': 0.006852523,
  'mau': 8965000,
  'dau': 267100,
  'details': ['Chinese Name: 卓易市场',
   '',
   'Zhuoyi is an independent app store developed by a Shanghai-based company. In addition to the Zhuoyi app store, they also develop and maintain the Android-based Freeme OS.']},
 {'short_name': 'ZTE App Store',
  'image': <StaticImage src="../../images/stores/zte.jpeg" {...imgProps} />,
  'website': 'https://www.ztems.com',
  'text': 'ZTE App Store',
  'mau_market': 0.005970217,
  'mau': 7810700,
  'dau': 400400,
  'details': ['Chinese name: 中兴应用商店',
   '',
   'ZTE is was founded in 1985 in Shenzhen, China, and is one of China’s leading telecom equipment manufacturers. It is one of the top five mobile phone manufacturers in China. The ZTE app store comes preinstalled on its smartphones in China. ZTE now has a strong international presence, with subsidiaries around the world.']},
 {'short_name': 'LeTV Store',
  'image': <StaticImage src="../../images/stores/letv_store.png" {...imgProps} />,
  'website': 'https://mobile.le.com',
  'text': 'LeTV Store',
  'mau_market': 0.00585197,
  'mau': 7656000,
  'dau': 51000,
  'details': ['Chinese Name: 乐视应用商店',
   '',
   "Founded in 2016, LeTV Interactive Entertainment Technology Co. Ltd. provides mobile devices and smart TVs with integrated apps and games. LeTV is pre-installed on all LeTV's smart TVs"]},
 {'short_name': 'PP Assistant',
  'image': <StaticImage src="../../images/stores/pplogo.png" {...imgProps} />,
  'website': 'http://www.pp.cn',
  'text': 'PP Assistant',
  'mau_market': 0.005809013,
  'mau': 7599800,
  'dau': 915600,
  'details': ['Chinese Name: PP助手',
   '',
   "PP Assistant is Alibaba's official app store in China. In addition to apps for Android, PP Assistant also has two app stores for iOS, including one for iOS devices that have been “jailbroken”."]},
 {'short_name': 'Coolpad',
  'image': <StaticImage src="../../images/stores/coolpad.png" {...imgProps} />,
  'website': 'http://www.2265.com/soft/56296.html',
  'text': 'Coolpad',
  'mau_market': 0.005393658,
  'mau': 7056400,
  'dau': 712300,
  'details': ['Chinese Name: 酷派应用商店',
   '',
   'Previously known as China Wireless Technologies Limited, CoolPad Group was founded in 1993 and began to make mobile phones in 2002. Its market penetration in China has diminished substantially in recent years as it has faced heavy losses and a series of ownership changes. The CoolPad app store comes preinstalled on its devices.']},
 {'short_name': 'Lenovo Le Store',
  'image': <StaticImage src="../../images/stores/lenovo.png" {...imgProps} />,
  'website': 'https://lestore.lenovo.com',
  'text': 'Lenovo Le Store',
  'mau_market': 0.004614847,
  'mau': 6037500,
  'dau': 387300,
  'details': ['Chinese Name: 联想乐商店',
   '',
   'Lenovo is a Chinese multinational company, founded in 1984 and headquartered in Beijing. By 2019, it was the world’s largest PC vendor by unit sales. They also develop storage devices, IT management software, smartphones, tablets and smart TVs running the Android operating system. The Lenovo Le Store is preinstalled on many of its devices, including Smart TVs.']},
 {'short_name': 'Gionee',
  'image': <StaticImage src="../../images/stores/gionee.png" {...imgProps} />,
  'website': 'https://m.wbto.cn/soft/60876.html',
  'text': 'Gionee',
  'mau_market': 0.004628224,
  'mau': 6055000,
  'dau': 38300,
  'details': ['Chinese Name: 金立软件商店, also known as 易用汇',
   '',
   ' Launched in 2011, Gionee app store was developed for android users.']},
 {'short_name': 'China Mobile MM Store',
  'image': <StaticImage src="../../images/stores/mm.png" {...imgProps} />,
  'website': 'https://touch.10086.cn/goods/200_200_1040821_1035095.html',
  'text': 'China Mobile MM Store',
  'mau_market': 0.004111514,
  'mau': 5379000,
  'dau': 29500,
  'details': ['Chinese Name: MM 应用商场',
   '',
   'China Mobile is one of three state-owned telecoms companies in China that provides mobile services across Mainland China. Publication of apps in this app store requires SDK integration (the process of importing a software development kit). MM store will also take a 30% share of revenue.']},
 {'short_name': 'HiMarket',
  'image': <StaticImage src="../../images/stores/himarket.png" {...imgProps} />,
  'website': 'http://apk.hiapk.com',
  'text': 'HiMarket',
  'mau_market': 0.004055715,
  'mau': 5306000,
  'dau': 28500,
  'details': ['Chinese Name: 安卓市场',
   'HiMarket was founded in Sept 2009 by 91 Wireless (also the creator of 91 Wireless Assistant), which was later bought out by Baidu in 2013 for $1.85 billion USD. The HiMarket app store has both an English and Chinese version of its app store.']},
 {'short_name': 'Sogou App Store',
  'image': <StaticImage src="../../images/stores/sogou_app_store.png" alt="logo" {...imgProps} />,
  'website': 'http://as.sogou.com/',
  'text': 'Sogou App Store',
  'mau_market': 0.002299207,
  'mau': 3008000,
  'dau': 29000,
  'details': ['Chinese Name: 搜狗应用',
   '',
   'Sogou App Store app is a helpful assistant for managing smartphones. This software is dedicated to providing users with a rich, high-quality, and personalized experience in terms of Android software and games.']},
 {'short_name': 'Anzhi Market',
  'image': <StaticImage src="../../images/stores/anzhi.png" {...imgProps} />,
  'website': 'http://www.anzhi.com/',
  'text': 'Anzhi Market',
  'mau_market': 0.002015475,
  'mau': 2636800,
  'dau': 118800,
  'details': ['Chinese Name: 安智市场',
   '',
   'Anzhi Market is a Chinese app store that has been around for many years. It has an active community that allows members to discuss and rate Android apps. Anzhi Market was launched in June 2010. ']},
 {'short_name': 'Meizu Flyme',
  'image': <StaticImage src="../../images/stores/meizu.png" {...imgProps} />,
  'website': 'https://app.meizu.com',
  'text': 'Meizu Flyme',
  'mau_market': 0.001494408,
  'mau': 1955100,
  'dau': 109100,
  'details': ['Chinese Name: 魅族应用商店',
   '',
   'Established in 2003 but expanding into the smartphone market in 2008, Meizu designs and produces smartphones with simple interfaces and easy-to-use features. Meizu Flyme is the official app store that is pre-installed on all Meizu smartphones. ']},
 {'short_name': 'Coolapk',
  'image': <StaticImage src="../../images/stores/coolmarket.png" {...imgProps} />,
  'website': 'https://www.coolapk.com',
  'text': 'Coolapk',
  'mau_market': 0.001260818,
  'mau': 1649500,
  'dau': 256500,
  'details': ['Chinese Name: 酷安, also known as 酷市场',
   '',
   "Formerly known as Cool Market, Coolapk is an independent app store, meaning the company produces no hardware or related products. The company also has a WeChat public account called Ku'an Digital, a WeChat mini-program, and a channel on bilibili.com where they host reviews of tech products and software. The company’s slogan is “Discover New Life in Science and Technology”."]},
 {'short_name': 'AppChina',
  'image': <StaticImage src="../../images/stores/appchina.png" {...imgProps} />,
  'website': 'http://www.appchina.com/',
  'text': 'AppChina',
  'mau_market': 0.001085779,
  'mau': 1420500,
  'dau': 121600,
  'details': ['Chinese name: 应用汇',
   '',
   'AppChina - not to be confused with our company, AppInChina - is an independent app store. Within 9 months after it launched in 2011, it had more than 100 million downloads. It was one of the early projects of the Innovation Workshop investment group, hosting apps and games through its app store app and website.']},
 {'short_name': 'Google Play',
  'image': <StaticImage src="../../images/stores/googleplay.png" {...imgProps} />,
  'website': 'https://play.google.com',
  'text': 'Google Play',
  'mau_market': 0.00114945,
  'mau': 1503800,
  'dau': 89500,
  'details': ['Chinese name: 谷歌应用商店',
   '',
   'Google Play is the official Android app store from Google, originally launched alongside its Android operating system in October 2008 as Android Market. Due to government restrictions, Google Play is not available in China, paving the way for the creation of an ecosystem of third-party Android app stores in Mainland China. For Chinese users, it is only accessible via a VPN.']},
 {'short_name': 'DangBei Market',
  'image': <StaticImage src="../../images/stores/dangbei_app_store.png" alt="logo" {...imgProps} />,
  'website': 'https://www.dangbei.com/apps/',
  'text': 'DangBei Market',
  'mau_market': 0.000157841,
  'mau': 206500,
  'dau': 22100,
  'details': ['Chinese Name: 当贝市场',
   '',
   'Dangbei Market is smart TV application store developed by Dangbei Network in  2014. It supports the Android system and is a platform specifically designed for smart TVs and Android TV boxes.']}]

export const APP_STORES = RAW_APP_STORES.map((item, index) => {
  let mau_market = item.mau_market * 100
  let mau = item.mau
  let dau = item.dau

  // We need to make sure mau_market, mau and dau are strings
  if (typeof mau_market !== 'string') {
    mau_market = mau_market.toString()
  }

  if (typeof mau !== 'string') {
    mau = mau.toString()
  }

  if(typeof dau !== 'string') {
    dau = dau.toString()
  }

  // We want to format the numbers to have commas truncate the mau_market to 2 decimal places
  mau_market = parseFloat(mau_market).toFixed(2)
  mau_market = mau_market.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  mau = mau.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  dau = dau.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Attach the % sign to the mau_market
  mau_market = mau_market + '%'

  return { 
    ...item, 
    index: index + 1,
    mau_market: mau_market,
    mau: mau,
    dau: dau
  }
});
