import baiduapp from "images/chinese-apps/baidu.png";
import gaode_maps from "images/chinese-apps/gaode-maps.png";
import iqiyi from "images/chinese-apps/iqiyi.png";
import baidu_map from "images/chinese-apps/baidu_map.png";
import pinduoduo from "images/chinese-apps/pinduoduo.png";
import quick_worker from "images/chinese-apps/quick_worker.png";
import qqapp from "images/chinese-apps/qq.png";
import sogou_input from "images/chinese-apps/sogou-input.png";
import taobao from "images/chinese-apps/taobao.png";
import wechat from "images/chinese-apps/wechat.png";
import weibo from "images/chinese-apps/weibo.png";
import alipay_app from "images/chinese-apps/alipay_app.png";
import qqbrowser_app from "images/chinese-apps/qqbrowser_app.png";
import tencent_video_app from "images/chinese-apps/tencent_video_app.png";
import toutiao from "images/chinese-apps/toutiao.png";
import tiktok from "images/chinese-apps/tiktok.png";
import jingdong from "images/chinese-apps/jingdong.png";
import kugou from "images/chinese-apps/kugou.png";
import qq_music from "images/chinese-apps/qq-music.png";
import uc_browser from "images/chinese-apps/uc-browser.png";
import huawei_app from "images/chinese-apps/huawei_app.png";
import kuwo from "images/chinese-apps/kuwo.png";
import mango_tv from "images/chinese-apps/mango_tv.png";
import bilibili from "images/chinese-apps/bilibili.png";
import xiqua from "images/chinese-apps/xiqua.png";
import kuaishou from "images/chinese-apps/kuaishou-light.png";
import meituan from "images/chinese-apps/meituan.png";
import himalaya from "images/chinese-apps/himalaya_cn.jpeg";
import red_note from "images/chinese-apps/red_note_2.png";
import ifly_keyboard from "images/chinese-apps/ifly_keyboard_2.webp";

// import red_book from "images/chinese-apps/little-red-book.png";
// import tencent_news from "images/chinese-apps/tencent_news.png";
// import wifi_master_key from "images/chinese-apps/wifi-master-key.png";
// import oppo from "images/stores/oppo.png";
// import tencentmyapp_app from "images/chinese-apps/tencentmyapp_app.png";
// import youku_app from "images/chinese-apps/youku_app.png";
// import tencent_mm_app from "images/chinese-apps/tencent_mm_app.png";
// import nail from "images/chinese-apps/nail.png";
// import xunfei from "images/chinese-apps/xunfei.png";

const raw_apps_data = [{'image': wechat,
  'text': 'WeChat',
  'mau': '1,061,215,100',
  'mau_change': '+0.24%',
  'details': ['Chinese Name: 微信',
   'Parent Company: Tencent',
   'App Category: Social/Networking']},
 {'image': alipay_app,
  'text': 'Alipay',
  'mau': '994,695,700',
  'mau_change': '+3.94%',
  'details': ['Chinese Name: 支付宝',
   'Parent Company: Alibaba',
   'App Category: Payment/Lifestyle']},
 {'image': tiktok,
  'text': 'Tiktok',
  'mau': '879,978,900',
  'mau_change': '+0.19%',
  'details': ['Chinese Name: 抖音',
   'Parent Company: ByteDance',
   'App Category: Short Video Platform']},
 {'image': taobao,
  'text': 'Taobao',
  'mau': '879,212,700',
  'mau_change': '-4.62%',
  'details': ['Chinese Name: 淘宝',
   'Parent Company: Alibaba',
   'App Category: Shopping']},
 {'image': pinduoduo,
  'text': 'Pinduoduo',
  'mau': '699,020,200',
  'mau_change': '-0.63%',
  'details': ['Chinese Name: 拼多多',
   'Parent Company: Shanghai Xunmeng Information Technology',
   'App Category: Social E-commerce']},
 {'image': qqapp,
  'text': 'QQ',
  'mau': '653,147,200',
  'mau_change': '+1.11%',
  'details': ['Chinese Name: QQ',
   'Parent Company: Tencent',
   'App Category: Social Networking']},
 {'image': baidu_map,
  'text': 'Baidu Map',
  'mau': '643,706,700',
  'mau_change': '+1.47%',
  'details': ['Chinese Name: 百度地图',
   'Parent Company: Baidu',
   'App Category: Navigation']},
 {'image': quick_worker,
  'text': 'Kuaishou',
  'mau': '614,301,600',
  'mau_change': '+2.08%',
  'details': ['Chinese Name: 快手',
   'Parent Company: Beijing Kuaishou Technology',
   'App Category: Short Video Platform']},
 {'image': gaode_maps,
  'text': 'Auto Navi Map',
  'mau': '576,088,400',
  'mau_change': '-1.02%',
  'details': ['Chinese Name: 高德地图',
   'Parent Company: Alibaba',
   'App Category: Navigation']},
 {'image': baiduapp,
  'text': 'Baidu',
  'mau': '557,959,800',
  'mau_change': '+0.63%',
  'details': ['Chinese Name: 百度',
   'Parent Company: Baidu',
   'App Category: Information']},
 {'image': sogou_input,
  'text': 'Sogou Keyboard',
  'mau': '496,765,400',
  'mau_change': '-1.84%',
  'details': ['Chinese Name: 搜狗输入法',
   'Parent Company: Sogou',
   'App Category: Chinese Keyboard']},
 {'image': jingdong,
  'text': 'JD',
  'mau': '446,271,700',
  'mau_change': '-7.32%',
  'details': ['Chinese Name: 京东',
   'Parent Company: Jingdong',
   'App Category: E-commerce']},
 {'image': weibo,
  'text': 'Weibo',
  'mau': '442,961,600',
  'mau_change': '-2.34%',
  'details': ['Chinese Name: 微博',
   'Parent Company: Sina',
   'App Category: Social']},
 {'image': iqiyi,
  'text': 'iQIYI',
  'mau': '380,556,100',
  'mau_change': '-3.89%',
  'details': ['Chinese Name: 爱奇艺',
   'Parent Company: Baidu',
   'App Category: Video Streaming']},
 {'image': kuaishou,
  'text': 'Kuaishou Light',
  'mau': '365,050,400',
  'mau_change': '+2.64%',
  'details': ['Chinese Name: 快手极速版',
   'Parent Company: Beijing Kuaishou Technology',
   'App Category: Short Video Platform']},
 {'image': huawei_app,
  'text': 'HUAWEI AppGallery',
  'mau': '332,745,600',
  'mau_change': '+2.36%',
  'details': ['Chinese Name: 华为应用市场',
   'Parent Company: Huawei',
   'App Category: App Store']},
 {'image': tencent_video_app,
  'text': 'Tencent Video',
  'mau': '309,681,100',
  'mau_change': '-1.75%',
  'details': ['Chinese Name: 腾讯视频',
   'Parent Company: Tencent',
   'App Category: Video Streaming']},
 {'image': qqbrowser_app,
  'text': 'QQ Browser',
  'mau': '269,901,600',
  'mau_change': '+2.9%',
  'details': ['Chinese Name: QQ浏览器',
   'Parent Company: Tencent',
   'App Category: Utilities']},
 {'image': mango_tv,
  'text': 'Mango TV',
  'mau': '266,840,400',
  'mau_change': '-4.03%',
  'details': ['Chinese Name: 芒果TV',
   'Parent Company: Hunan Broadcasting System',
   'App Category: Video Streaming']},
 {'image': toutiao,
  'text': 'Jinritoutiao',
  'mau': '254,001,100',
  'mau_change': '+6.91%',
  'details': ['Chinese Name: 今日头条',
   'Parent Company: ByteDance',
   'App Category: Information']},
 {'image': meituan,
  'text': 'Meituan',
  'mau': '246,668,000',
  'mau_change': '+8.73%',
  'details': ['Chinese Name: 美团',
   'Parent Company: Meituan',
   'App Category: Lifestyle']},
 {'image': kugou,
  'text': 'Kugou Music',
  'mau': '235,059,900',
  'mau_change': '+4.11%',
  'details': ['Chinese Name: 酷狗音乐',
   'Parent Company: Guangzhou Kugou Computer Technology',
   'App Category: Music']},
 {'image': qq_music,
  'text': 'QQ Music',
  'mau': '230,919,900',
  'mau_change': '+4.34%',
  'details': ['Chinese Name: QQ音乐',
   'Parent Company: Tencent',
   'App Category: Music']},
 {'image': ifly_keyboard,
  'text': 'iFly Keyboard',
  'mau': '216,378,400',
  'mau_change': '+2.62%',
  'details': ['Chinese Name: 讯飞输入法',
   'Parent Company: iFlytek',
   'App Category: Chinese Keyboard']},
 {'image': kuwo,
  'text': 'Kuwo Music',
  'mau': '214,120,500',
  'mau_change': '+1.1%',
  'details': ['Chinese Name: 酷我音乐',
   'Parent Company: Beijing Kuwo Technology',
   'App Category: Music']},
 {'image': red_note,
  'text': 'RedNote',
  'mau': '213,070,800',
  'mau_change': '+4.45%',
  'details': ['Chinese Name: 小红书',
   'Parent Company: Xingyin',
   'App Category: Social']},
 {'image': himalaya,
  'text': 'Himalaya',
  'mau': '212,280,400',
  'mau_change': '-3.47%',
  'details': ['Chinese Name: 喜马拉雅',
   'Parent Company: Ximalaya',
   'App Category: Podcasts/Educational Audio ']},
 {'image': uc_browser,
  'text': 'UC Browser',
  'mau': '196,946,900',
  'mau_change': '+0.44%',
  'details': ['Chinese Name: UC浏览器',
   'Parent Company: Guangzhou Dongjing Computer Technology',
   'App Category: Utilities']},
 {'image': xiqua,
  'text': 'Xigua Video',
  'mau': '177,657,400',
  'mau_change': '+3.09%',
  'details': ['Chinese Name: 西瓜视频',
   'Parent Company: ByteDance',
   'App Category: Video Sharing']},
 {'image': bilibili,
  'text': 'bilibili',
  'mau': '199,043,100',
  'mau_change': '+1.89%',
  'details': ['Chinese Name: 哔哩哔哩',
   'Parent Company: Shanghai Kuanyu Digital Technology',
   'App Category: Video Sharing']}];

// Remove the mau_change property from the data
const processed_data = raw_apps_data.map((it) => {
  delete it.mau_change;
  return it;
});

export const APPS = processed_data.map((it, index) => ({
  ...it,
  index: index + 1,
}));
