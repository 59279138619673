import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Layout from "components/shared/Layout";
import Apps from "components/market/Apps";
import CTA from "components/shared/CTA";
import MarketRelatedContent from "components/market/MarketRelatedContent";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/applications.svg";
export default () => (
  <>
    <Meta url="/market/apps/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="App"
      black="Index"
      text={
        <p>
          The AppInChina App Index is a ranking of the best-performing Android
          apps in the Chinese market based on number of monthly active users.
          <br />
          <b>Last Updated: December 2024</b>
        </p>
      }
    />
    <br/><br/>
    <Layout>
      <MarketRelatedContent>
        <Apps />
      </MarketRelatedContent>
      <br />
      <br />
    </Layout>
    <CTA titleLevel="h3" />
    <Footer titleLevel="h4" />
  </>
);
