export const CLOUD_PROVIDERS = [{'name': 'Alicloud',
   'website': 'https://www.alibabacloud.com/',
   'market_share': 36.0,
   'revenue_m': 3672.0,
   'details': ['Chinese Name: 阿里云',
    'Parent Company: Alibaba',
    'Alibaba Cloud, founded in 2009, is a global leader in cloud computing and artificial intelligence, providing a comprehensive suite of global cloud services to thousands of enterprises, developers, and governments organizations in more than 200 countries and regions. Alibaba offers the most scalable cloud computing and is China’s most significant provider in data Management Services. According to IDC, Alibaba Cloud is solidly positioned as the No.1 cloud computing service provider in China. According to Gartner, Alibaba Cloud ranks 1st among cloud service providers in Asia Pacific and 3rd in the world, outranking Google Cloud.']},
  {'name': 'Huawei Cloud',
   'website': 'https://www.huaweicloud.com/intl/en-us/',
   'market_share': 19.0,
   'revenue_m': 1938.0,
   'details': ['Chinese Name: 华为云',
    'Parent Company: HUAWEI',
    "Founded in 2011, HUAWEI Cloud is a leading cloud service provider, bringing HUAWEI's 30-plus years of expertise together in ICT infrastructure products and solutions. HUAWEI Cloud is committed to bringing affordable, effective, and reliable cloud and AI services through technological innovation. By the end of 2019, HUAWEI Cloud has launched 210+ cloud services and 210+ solutions."]},
  {'name': 'Tencent Cloud',
   'website': 'https://intl.cloud.tencent.com/',
   'market_share': 15.0,
   'revenue_m': 1530.0,
   'details': ['Chinese Name: 腾讯云',
    'Parent Company: Tencent',
    "Founded in 2013, Tencent Cloud provides customers with industry-leading cloud products and services. Tencent Cloud operates 53 availability zones in 26 geographical regions around the world, serving millions of customers from more than 100 countries and providing them with powerful, secure and stable cloud products and services to support the development needs of different businesses. Tencent cloud is widely favored by the gaming and video broadcasting industry, where 70% of gaming companies have chosen Tencent's cloud service"]},
  {'name': 'Baidu Cloud',
   'website': 'https://intl.cloud.baidu.com/',
   'market_share': 8.0,
   'revenue_m': 816.0,
   'details': ['Chinese Name: 百度云',
    'Parent Company: Baidu',
    'Founded in 2012 and became available for the public in 2015, Baidu Cloud is a comprehensive Cloud service provided by Baidu Inc., offering services and solutions such as cloud storage service, client software, file management, resources sharing, and third party integration etc.. Leveraging its unique, “full-stack” competitive edge in AI, algorithms, open platforms and developer ecosystems, Baidu has delivered a strong execution on its cloud and intelligence integration strategy.']},
  {'name': 'State Cloud',
   'website': 'https://www.ctclouds.com/#homepage',
   'market_share': 6.3,
   'revenue_m': 642.6,
   'details': ['Chinese Name: 天冀云',
    'Parent Company: China Telecom International',
    "Established in 2012 and headquartered in Hong Kong, China Telecom International has since built 63 overseas PoP nodes and 40 OTN nodes, with international transmission export bandwidth and transcontinental capacity exceeding 9T, and resources on 33 submarine cables. Based on China Telecom's virtual cloud service infrastructure resource pool and professional service capabilities, China Telecom International provides customers with shared or exclusive virtual cloud service resources (including computing, storage and network) leasing services."]},
  {'name': 'AWS China',
   'website': 'https://www.amazonaws.cn/en/',
   'market_share': 5.8,
   'revenue_m': 519.6,
   'details': ['Chinese Name: 亚马逊云端服务',
    'Parent Company: Amazon',
    'Founded in 2006, AWS was the first to offer cloud computing infrastructure as a service and has since been the No.1 cloud provider in the global market. To provide the best experience for customers in China and to comply with China’s legal and regulatory requirements, Amazon Web Services has collaborated with China local partners with proper telecom licenses for delivering cloud services. The service operator and provider for Amazon Web Services China (Beijing) Region based out of Beijing and adjacent areas is Beijing Sinnet Technology Co., Ltd. (Sinnet, 光环新网), and the service operator and provider for Amazon Web Services (Ningxia) Region based out of Ningxia is Ningxia Western Cloud Data Technology Co., Ltd. (NWCD 西云数据).']},
  {'name': 'Kingsoft Cloud',
   'website': 'https://en.ksyun.com/',
   'market_share': 2.5,
   'revenue_m': 255.0,
   'details': ['Chinese Name: 金山云',
    'Parent Company: KINGSOFT',
    'Founded in 2012, KINGSOFT Cloud has developed a comprehensive suite of cloud computing services, with solutions for many industries, such as government, finance, AIoT, healthcare, industrial engineering, media, video, game, education, Internet, content services. These solutions integrate with AI, big data, IoT, blockchain, edge computing, and AR/VR technologies.',
    '']}].map((it, index) => ({
      ...it, 
      // if the market share value does not have a % sign, add it
      market_share: it.market_share.toString().includes('%') ? it.market_share : `${it.market_share}%`,
      // The revenue should be formatted with commas
      revenue_m: it.revenue_m.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      index: index + 1 
   }));


