import React from "react";
import * as classes from "./index.module.less";
import "./styles.css";

import { getRelatedContent, formatBlogDate } from "src/utils/functions";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ShareButtons from "components/shared/ShareButtons";
import PostSideContent from "./PostSideContent";

export default ({ allWpPage, allWpPost, data, hideDate, toc, url }) => {
  const { title, content, featuredImage, author, date, related_content } = data;
  const image = getImage(featuredImage?.node?.localFile);

  let relatedContent = getRelatedContent(related_content, allWpPage, allWpPost);

  
  function fixContentString(inputString){
    function removeWww(inputString) {
      // Define a regular expression pattern to match URLs with "www"
      var pattern = /href="https:\/\/www\.appinchina.co\/([a-zA-Z0-9.-]+)\/([^"]+)"/g;
    
      // Use the replace method to remove "www" from the URLs in the inputString
      var modifiedString = inputString.replace(pattern, 'href="https://appinchina.co/$1/$2"');
    
      return modifiedString;
    }
  
    function addTailSlash(inputString) {
      var pattern = /href="https:\/\/(www\.)?([a-zA-Z0-9.-]+)\/([^"]+)(\/)?"(.*?)"/g;
      var modifiedString = inputString.replace(pattern, 'href="https://$2/$3/"$5"');
      return modifiedString;
    }
  
    function fixDuplicatedSlash(inputString) {
      var pattern = /href="https:\/\/(www\.)?([a-zA-Z0-9.-]+)\/([^"]+?)(\/+)"(.*?)"/g;
      var modifiedString = inputString.replace(pattern, 'href="https://$2/$3/"$5"');
      return modifiedString;
    }
    // This function will fix internal links to make sure that all the links are like "https://appinchina.co/som-url/"
    var modifiedString = removeWww(inputString);
    modifiedString = addTailSlash(modifiedString);
    modifiedString = fixDuplicatedSlash(modifiedString);
    modifiedString = modifiedString.replace(/i1.wp.com\//g, "");
    return modifiedString;
  }

  return (
    <div className={classes.container}>
      <h1>{title}</h1>
      {!hideDate ? (
        <>
          <div className={classes.date}>
            <span>
              By <b>{author?.node ? author.node.name : "AppInChina"}</b>
            </span>
            <i></i>
            <span>Last Updated on {formatBlogDate(date)}</span>
          </div>

          <div className={classes.share}>
            <ShareButtons title={title} />
          </div>
        </>
      ) : (
        <br />
      )}
      {image !== null && image !== undefined 
        ? <div className={classes.image}>
          {image ? (
            <GatsbyImage image={image} alt={title} loading="eager" />
          ) : null}
        </div>
        : null
      }
      <PostSideContent 
        // The side content includes the 'Related Content List' and the 'Contents' table
        items={relatedContent}
        title="Related Content"
        tocData={toc}
      >
        <>
          <div
            className={classes.content}
            dangerouslySetInnerHTML={{
              __html: fixContentString(content),
            }}
          >
          </div>
        </>
      </PostSideContent>
    </div>
  );
};
