import React from "react";
import Table from "components/shared/Table";
import { APPS } from "src/utils/market/apps";
import * as classes from "../rankings.module.less";

export default () => {
  let columns = [
    {
      hideResponsiveTitle: true,
      title: "App Store",
      dataIndex: "text",
      render: (text, element) => (
        <div className={classes.storeName}>
          <i>{element.index}</i>
          <img
            src={element.image}
            className={classes.logo}
            alt="logo"
            width="32"
            height="32"
            loading="lazy"
          />
          {element.website ? (
            <a href={element.website} rel="noopener noreferrer" target="_blank">
              <span>{text}</span>
            </a>
          ) : (
            <span>{text}</span>
          )}
        </div>
      ),
    },
    {
      title: "Monthly Active Users (MAU)",
      dataIndex: "mau",
      className: classes.right,
    },
    // {
    //   title: "MAU Change",
    //   dataIndex: "mau_change",
    //   className: classes.right,
    // },
  ];

  return (
    <Table
      responsive
      dataSource={APPS}
      columns={columns}
      rowKey="index"
      expandedRowRender={(record) => (
        <div>
          {record.details.map((item) => (
            <p className={classes.detailsP}>{item}</p>
          ))}
        </div>
      )}
    />
  );
};
