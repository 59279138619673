import React from "react";
import * as classes from "./index.module.less";

let scroll = (e) => {
  e.preventDefault();
  if (typeof window === "undefined" || typeof document == "undefined") return;
  function scrollToTarget(target) {
    window.scrollTo({
      left: 0,
      top: target.offsetTop - 70,
      behavior: "smooth",
    });
  }
  scrollToTarget(document.querySelector(e.target.getAttribute("href")));
};

// When the user clicks on the link, scroll to the target and send an action-record to aic minimal backend
function sendActionRecord() {
  if (typeof window === "undefined" || typeof document == "undefined") return;
  // get the current url
  let currentUrl = window.location.href;

  // use fetch to make a post request to localhost:8000/toc-click/
  // include the body as a json object with the current url pageUrl: currentUrl
  // print the response to the console
  let apiUrl = "https://minbackend.appinchina.co/toc-click/";
  let apiUrlLocal = "http://localhost:8080/toc-click/";

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ pageUrl: currentUrl }),
  })
  .then((response) => response.json())
  .then((data) => {
    console.log("Success:", data);
  })
  .catch((error) => {
    console.error("Error:", error);
  });
}

const renderTableOfContentItems = (items, level) => {
  return (
    <ul
      style={{
        padding: level === 0 ? 0 : "auto",
      }}
    >
      {items.map(({ url, title, items }) => (
        <li key={url}>
          <div>
            <a href={url} 
              onClick={(e)=>{
                scroll(e)
                sendActionRecord()
              }}>
              {title}
            </a>
            {items && items.length && renderTableOfContentItems(items, level + 1)}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ({ toc }) =>
  toc ? (
    <aside className={classes.container}>
      <h2>Contents</h2>
      {renderTableOfContentItems(toc.items, 0)}
    </aside>
  ) : null;
