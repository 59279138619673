import React from "react";
import Table from "components/shared/Table";
import { BRANDS } from "src/utils/market/brands";
import * as classes from "../rankings.module.less";

export default () => {
  let columns = [
    {
      hideResponsiveTitle: true,
      title: "",
      dataIndex: "image",
      render: (image, element) => (
        <div className={classes.storeName}>
          <i>{element.index}</i>
          <div className={classes.largeImage}>{image}</div>
        </div>
      ),
    },
    {
      hideResponsiveTitle: true,
      title: "Name",
      dataIndex: "text",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Market Share",
      dataIndex: "percentage",
      className: classes.right,
    },
    {
      title: "Number of Devices",
      dataIndex: "num_devices",
      className: classes.right,
    },
  ];

  return <Table dataSource={BRANDS} columns={columns} rowKey="index" />;
};
