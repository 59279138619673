import React from "react";

import king_of_glory from "images/games/kingofglory.png";
import peace from "images/games/peace elite.png";
import happy_fun from "images/games/happy fun.png";
import happy_landlord from "images/games/happy landlord.png";
import mini_world from "images/games/mini world.png";
import line_fire from "images/games/cross the line of fire.png";
import snake_fight from "images/games/snake fight.png";
import qq_speed from "images/games/qq speed car.png";
import parkour_tom from "images/games/parkour tom cat.png";
import minecraft from "images/games/minecraft.png";
import ballfight from "images/games/Ball Fight.png";
import jj_landlord from "images/games/jj landlord.png";
import vital_knight from "images/games/vital_knight.png";
import subway_parkour from "images/games/subway parkour.png";
import sausage_party from "images/games/sausage_party.png";
import piano_block from "images/games/piano_block.png";
import archer_battle from "images/games/archer_battle.png";
import pp_play from "images/games/pp play.png";
import internet_coffee from "images/games/internet_coffee.png";
import animal_farm from "images/games/animal_farm.png";
import clash_of_clans from "images/games/clash.jpeg";
import anipop from "images/games/anipop.png";
import lol from "images/games/lol.png";
import jellipop_match from "images/games/jellipop_match.jpeg";
import sky from "images/games/sky_children.webp";
import chan_chan from "images/games/chan_chan.png";
import daily_chess from "images/games/daily_chess.webp";
import arena_breakout from "images/games/arena_breakout.webp";
import call_of_duty from "images/games/call_of_duty.webp";
import fifth_persnonality from "images/games/fifth_persnonality.jpeg";
import naruto from "images/games/naruto.jpeg";
import happy_lanlord from "images/games/happy_lanlord.png";
import gold_shovel_battle from "images/games/gold_shovel_battle.webp";
import eggy_party from "images/games/eggy_party.webp";
import chinese_chess from "images/games/chinese_chess.png";
import soul_knight from "images/games/soul_knight.jpeg";


const getDeveloperLink = (url, title) => (
  <>
    <span>Publisher</span>:{" "}
    <span>
      <a rel="noopener noreferrer" target="_blank" href={url}>
        {title}
      </a>
    </span>
  </>
);

const img_mapping = {
  king_of_glory: king_of_glory,
  peace: peace,
  happy_fun: happy_fun,
  happy_landlord: happy_landlord,
  mini_world: mini_world,
  line_fire: line_fire,
  snake_fight: snake_fight,
  qq_speed: qq_speed,
  parkour_tom: parkour_tom,
  minecraft: minecraft,
  ballfight: ballfight,
  jj_landlord: jj_landlord,
  vital_knight: vital_knight,
  subway_parkour: subway_parkour,
  sausage_party: sausage_party,
  piano_block: piano_block,
  archer_battle: archer_battle,
  pp_play: pp_play,
  internet_coffee: internet_coffee,
  animal_farm: animal_farm,
  clash_of_clans: clash_of_clans,
  anipop: anipop,
  lol: lol,
  jellipop_match: jellipop_match,
};

let urls_mapping = {
  "Shenzhen Tencent Computer System Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=1",
  "Element Technology (Beijing) Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=3635",
  "Shenzhen Mini Play Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=50519",
  "Wuhan Weipai Network Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=2549",
  "Huaian Huaiyangxin Network Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=61511",
  "Shanghai Wangzhiyiwu World Network Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=51738",
  PapaBox:
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=61529",
  "Beijing Cheetah Network Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=29821",
  "Sports World (Beijing) Network Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=1362",
  "Giant Mobile Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=5454",
  "Guangzhou 4399 Information Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=5267",
  "Topview Information System Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=103",
  "Tianjin Duoku Zitong Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=50520",
  "Shenzhen Dreamland Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=10542",
  "Xiamen really interesting Information Technology Co., Ltd.":
    "https://qianfan.analysys.cn/refine/view/pageApp/pageApp.html?pageType=company&developCompanyId=3778",
};

const raw_data = [{'image': king_of_glory,
  'text': 'King of Glory',
  'mau': 154817300,
  'details': ['Chinese Name: 王者荣耀',
   'Game Type: RPG (Role-Playing Game)/MOBA (Multiplayer Online Battle Arena)',
   'Publisher: Shenzhen Tencent Computer System Co.,Ltd.']},
 {'image': peace,
  'text': 'Peace Elite',
  'mau': 69560600,
  'details': ['Chinese Name: 和平精英',
   'Game Type: Shooting/FPS (First Person Shooter)',
   'Publisher: Shenzhen Tencent Computer System Co., Ltd.']},
 {'image': anipop,
  'text': 'Anipop',
  'mau': 61314300,
  'details': ['Chinese Name: 开心消消乐',
   'Game Type: Casual/Elimination',
   'Publisher: Happy Elements Technology (Beijing) Co., Ltd.']},
 {'image': minecraft,
  'text': 'Minecraft (Netease)',
  'mau': 19932300,
  'details': ['Chinese Name: 我的世界（网易）',
   'Game Type: Simulation/Sandbox',
   'Publisher: Shanghai NetEase MineWorld Network Technology Co.,Ltd']},
 {'image': eggy_party,
  'text': 'Eggy Party',
  'mau': 19407900,
  'details': ['Chinese Name: 蛋仔派对',
   'Game Type: Casual',
   'Publisher: Hangzhou NetEase Leihuo Technology.Co., Ltd.']},
 {'image': gold_shovel_battle,
  'text': 'Gold Shovel Battle',
  'mau': 17442000,
  'details': ['Chinese Name: 金铲铲之战',
   'Game Type: SLG (Simulation)',
   'Publisher: Shenzhen Tencent Computer System Co., Ltd.']},
 {'image': happy_lanlord,
  'text': 'Happy Landlord',
  'mau': 16668300,
  'details': ['Chinese Name: 欢乐斗地主',
   'Game Type: TAB (Board games)/Chess and Cards',
   'Publisher: JJWorld (Beijing) Network Technology Co., Ltd.']},
 {'image': mini_world,
  'text': 'Mini World',
  'mau': 14564700,
  'details': ['Chinese Name: 迷你世界',
   'Game Type: Simulation/3D Sandbox',
   'Publisher: Shenzhen Miniwan Technology Co.,Ltd']},
 {'image': naruto,
  'text': 'Naruto',
  'mau': 13520900,
  'details': ['Chinese Name: 火影忍者',
   'Game Type: ACT (Action Game)/Fighting',
   'Publisher: Shenzhen Tencent Computer System Co., Ltd.']},
 {'image': line_fire,
  'text': 'Cross Fire',
  'mau': 12858400,
  'details': ['Chinese Name: 穿越火线',
   'Game Type: Shooting/FPS (First Person Shooter)',
   'Publisher: Shenzhen Tencent Computer System Co., Ltd.']},
 {'image': fifth_persnonality,
  'text': 'Fifth Personality',
  'mau': 12821000,
  'details': ['Chinese Name: 第五人格',
   'Game Type: RPG (Role-Playing Game)/MOBA (Multiplayer Online Battle Arena)',
   'Publisher: Hangzhou NetEase Leihuo Technology.Co., Ltd.']},
 {'image': lol,
  'text': 'League of Legends',
  'mau': 10990900,
  'details': ['Chinese Name: 英雄联盟手游',
   'Game Type: RPG (Role-Playing Game)/MOBA (Multiplayer Online Battle Arena)',
   'Publisher: Shenzhen Tencent Computer System Co., Ltd.']},
 {'image': sky,
  'text': 'Sky: Children of the Light',
  'mau': 10362900,
  'details': ['Chinese Name: 光·遇',
   'Game Type: Adventure ',
   'Publisher: Hangzhou NetEase Leihuo Technology.Co., Ltd.']},
 {'image': call_of_duty,
  'text': 'Call of Duty Mobile',
  'mau': 9164100,
  'details': ['Chinese Name: 使命召唤手游',
   'Game Type: Shooting/FPS (First Person Shooter)',
   'Publisher: Shenzhen Tencent Computer System Co., Ltd.']},
 {'image': chinese_chess,
  'text': 'Chinese Chess',
  'mau': 8912700,
  'details': ['Chinese Name: 中国象棋对弈打谱',
   'Game Type: TAB (Board games)/Chess and Cards',
   'Publisher: Fuzhou Gulou District Duolite Information Technology Co., Ltd']},
 {'image': jj_landlord,
  'text': 'JJ Landlord',
  'mau': 8895800,
  'details': ['Chinese Name: JJ斗地主',
   'Game Type: TAB (Board games)/Chess and Cards',
   'Publisher: JJWorld (Beijing) Network Technology Co., Ltd.']},
 {'image': snake_fight,
  'text': 'Snake Fight',
  'mau': 8763400,
  'details': ['Chinese Name: 贪吃蛇大作战',
   'Game Type: Casual/Control',
   'Publisher: Wuhan Weipai Network Technology Co., Ltd.']},
 {'image': arena_breakout,
  'text': 'Arena Breakout: Hardcore FPS',
  'mau': 8568700,
  'details': ['',
   'Chinese Name: 暗区突围',
   'Game Type: FPS (First Person Shooter)',
   'Publisher: Shenzhen Tencent Computer Systems Co., Ltd.']},
 {'image': daily_chess,
  'text': 'Daily Chess',
  'mau': 8394900,
  'details': ['Chinese Name: 天天象棋',
   'Game Type: TAB (Board games)/Chess and Cards',
   'Publisher: Shenzhen Tencent Computer System Co., Ltd.']},
 {'image': soul_knight,
  'text': 'Soul Knight',
  'mau': 7613800,
  'details': ['Chinese Name: 元气骑士',
   'Game Type: ACT (Action)',
   'Publisher: Guangzhou Four Three Nine Nine information Technology Co., Ltd.']}];

let processed_data = raw_data.map((it) => {
  // // If the mau_change is a float, convert it to a string
  // if (typeof it.mau_change === "number") {
  //   it.mau_change = it.mau_change.toFixed(2);
  // }

  // // Make sure a % sign is added to the end of the string
  // if (it.mau_change && !it.mau_change.includes("%")) {
  //   it.mau_change = it.mau_change + "%";
  // }

  // // Add a + sign to the beginning of the string if it's positive
  // if (it.mau_change && it.mau_change[0] !== "-") {
  //   it.mau_change = "+" + it.mau_change;
  // }

  // make sure the mau is a number and is printed with commas
  it.mau = it.mau.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return {
    ...it,
  };
});

// Remove the mau_change
processed_data = processed_data.map((it) => {
  delete it.mau_change;
  return it;
});

export const GAMES = processed_data.map((it, index) => ({
  ...it,
  index: index + 1,
}));