import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Layout from "components/shared/Layout";
import AppStores from "components/market/AppStores";
import MarketRelatedContent from "components/market/MarketRelatedContent";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/app-store.svg";
import CTA from "components/shared/CTA";
import { Link } from "gatsby";

export default () => (
  <>
    <Meta url="/market/app-stores/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="App Store"
      black="Index"
      text={
        <p>
          {" "}
          The AppInChina App Store Index is the market-leading index of China’s largest Android app stores. We update our index regularly using the most accurate data sources available.
          <br /> <br />
          If you want to publish a SaaS Platform and employ cloud services,
          please also see our{" "}
          <Link to="/market/cloud-provider/">Cloud Provider Index</Link>, where
          you can find information on the leading cloud services providers in
          China. <br /> <br />
          <b>Last Updated: November 2024</b>
        </p>
      }
    />
    <br />
    <br />
    <Layout>
      <MarketRelatedContent>
        <AppStores />
      </MarketRelatedContent>
      <br />
      <br />
    </Layout>
      <CTA titleLevel="h3" />
    <Footer titleLevel="h4" />
  </>
);
