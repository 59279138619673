import React from "react";
import { Link } from "gatsby";
import * as classes from "./index.module.less";
import TOC from "components/shared/TOC";

export default ({ 
  children, 
  items, 
  title, 
  tocData = null,
  titleLevel="" }) => {
  let titleRender = ""
  if (titleLevel=="") {
    titleRender = <h4>{title}</h4>;
  } else if(titleLevel=="h3") {
    titleRender = <h3>{title}</h3>;
  } else if(titleLevel=="h2") {
    titleRender = <h2>{title}</h2>;
  }

  let renderSideContent = items?.length || tocData;

  let tocOnTheSide = true;
  let tocOnTop = false;

  // render the Table of Contents only if the width is greater than 740px
  if (typeof window !== "undefined") {
    if (window.innerWidth < 740) {
      tocOnTheSide = false;
      tocOnTop = true;
    }
  }

  // listen for resize events
  // if (typeof window !== "undefined") {
  //   window.addEventListener("resize", () => {
  //     if (window.innerWidth < 740) {
  //       tocOnTheSide = false;
  //       tocOnTop = true;
  //     } else {
  //       tocOnTheSide = true;
  //       tocOnTop = false;
  //     }
  //   });
  // }
  
  return renderSideContent ? (
      <div className={classes.container}>
        <div className={classes.children}>
          {tocOnTop && tocData && <TOC toc={tocData} />}
          {children}
        </div>

        <div className={classes.side}>
          {(tocData && tocOnTheSide) && <TOC toc={tocData} />}
          
          {titleRender}
  
          {items?.map((it, key) => (
            <Link to={it.url} key={key}>
              {it.title || it.node?.title}
            </Link>
          ))}
        </div>
      </div>
    ) : (
      children
    );
}

