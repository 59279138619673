import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Intro from "components/our-company/Intro";
import Texts from "components/our-company/Texts";
import Photos from "components/our-company/Photos";
import GreenAlert from "components/shared/GreenAlert";
import Layout from "components/shared/Layout";
import CTA from "components/shared/CTA";
import { Link } from "gatsby";

export default () => (
  <>
    <Meta url="/our-company/" />
    <Nav />

    <Layout>
      <Intro />
      <Texts />
      <Photos />
      <GreenAlert>
        <p>
          Looking to join an amazing company? Visit our{" "}
          <a
            href="https://careers.appinchina.co/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            careers page
          </a>
          .
        </p>
      </GreenAlert>
    </Layout>
    <CTA />
    <Footer />
  </>
);
