import React from "react";
import Table from "components/shared/Table";
import { CLOUD_PROVIDERS } from "src/utils/market/cloud-providers";
import * as classes from "../rankings.module.less";

export default () => {
  let columns = [
    {
      hideResponsiveTitle: true,
      title: "Cloud Provider",
      dataIndex: "name",
      render: (name, element) => (
        <div className={classes.storeName}>
          <i>{element.index}</i>
          {element.website ? (
            <a href={element.website} rel="noopener noreferrer" target="_blank" title={element.website}>
              <span>{name}</span>
            </a>
          ) : (
            <span>{name}</span>
          )}
        </div>
      ),
    },
    {
      title: "Market Share",
      dataIndex: "market_share",
      className: classes.right,
    },
    {
      title: "Revenue ($M)*",
      dataIndex: "revenue_m",
      className: classes.right,
    },
  ];

  return (
    <Table
      responsive
      dataSource={CLOUD_PROVIDERS}
      columns={columns}
      rowKey="index"
      expandedRowRender={(record) => (
        <div>
          {record.details.map((item) => (
            <p className={classes.detailsP}>{item}</p>
          ))}
        </div>
      )}
    />
  );
};
