import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const imgProps = {
  objectPosition: "50% 50%",
  loading: "lazy",
  placeholder: "blurred",
  height: 32,
  alt: "Brand Icon",
};

export const BRANDS = [{'image_str': 'huawei',
  'image': <StaticImage src="../../images/brands/huawei.png" {...imgProps} />,
   'text': 'Huawei',
   'percentage': 18.6,
   'num_devices': '209,696,251'},
  {'image_str': 'apple',
   'image': <StaticImage src="../../images/brands/apple.png" {...imgProps} />,
   'text': 'Apple',
   'percentage': 17.3,
   'num_devices': '195,040,062'},
  {'image_str': 'oppo',
   'image': <StaticImage src="../../images/brands/oppo.png" {...imgProps} />,
   'text': 'OPPO',
   'percentage': 16.6,
   'num_devices': '187,148,267'},
  {'image_str': 'vivo',
   'image': <StaticImage src="../../images/brands/vivo.png" {...imgProps} />,
   'text': 'VIVO',
   'percentage': 15.2,
   'num_devices': '171,364,678'},
  {'image_str': 'honor',
   'image': <StaticImage src="../../images/brands/honor.png" {...imgProps} />,
   'text': 'Honor',
   'percentage': 11.4,
   'num_devices': '128,523,509'},
  {'image_str': 'redmi',
   'image': <StaticImage src="../../images/brands/redmi.png" {...imgProps} />,
   'text': 'Redmi',
   'percentage': 6.5,
   'num_devices': '73,280,948'},
  {'image_str': 'iqoo',
   'image': <StaticImage src="../../images/brands/iqoo.svg" {...imgProps} />,
   'text': 'iQOO',
   'percentage': 5.02,
   'num_devices': '56,595,440'},
  {'image_str': 'xiaomi',
   'image': <StaticImage src="../../images/brands/xiaomi.png" {...imgProps} />,
   'text': 'Xiaomi',
   'percentage': 3.9,
   'num_devices': '43,968,569'},
  {'image_str': 'realme',
   'image': <StaticImage src="../../images/brands/realme.png" {...imgProps} />,
   'text': 'Realme',
   'percentage': 2.01,
   'num_devices': '22,660,724'},
  {'image_str': 'onePlus',
   'image': <StaticImage src="../../images/brands/oneplus.png" {...imgProps} />,
   'text': 'OnePlus',
   'percentage': 1.3,
   'num_devices': '14,656,190'},
  {'image_str': 'samsung',
   'image': <StaticImage src="../../images/brands/samsung.png" {...imgProps} />,
   'text': 'Samsung',
   'percentage': 0.9,
   'num_devices': '10,146,593'}].map((it, index) => ({ 
    ...it, 
    index: index + 1,
    percentage: `${it.percentage}%`, 
  }));