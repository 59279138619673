import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Layout from "components/shared/Layout";
import DeviceBrands from "components/market/DeviceBrands";
import CTA from "components/shared/CTA";
import MarketRelatedContent from "components/market/MarketRelatedContent";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/phone-case.svg";

export default () => (
  <>
    <Meta url="/market/device-brands/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="Mobile Device"
      black="Index"
      text={
        <p>
          This index shows the most popular brands of mobile device as a
          percentage of total active mobile devices in China.
          <br />
          <b>Last Updated: December 2024</b>
        </p>
      }
    />
    <br />
    <br />
    <Layout>
      <MarketRelatedContent>
        <DeviceBrands />
      </MarketRelatedContent>
      <br />
      <br />
    </Layout>
    <CTA />
    <Footer />
  </>
);
